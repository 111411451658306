<template>
  <div
    class="container-xs"
    style="display: flex; flex-direction: column; height: 100%"
  >
    <AppHeader back-to="/search/summary" />
    <div style="display: grid">
      <XLabel style="margin-top: 2rem">PROMOTIONS</XLabel>
      <XLabel
        for="first-name"
        tag="label"
        style="color: #656565; margin-top: 0.75rem"
        >Add Promo Code</XLabel
      >
      <FormInput
        id="first-name"
        v-model="forms.promo.value"
        minlength="2"
        type="text"
        required
        @valid="forms.vset.promo.value"
      />
      <Transition name="dropdown-s">
        <p v-if="error" class="app-info-msg" data-error>
          {{ error }}
        </p>
      </Transition>
      <Transition name="dropdown-s">
        <p v-if="success" class="app-info-msg" data-success>
          {{ success }}
        </p>
      </Transition>
    </div>
    <XButton
      id="save"
      type="large"
      style="margin-top: auto"
      :disabled="!forms.vget.promo.form"
      :is-loading="isLoading"
      @click="save"
    >
      APPLY
    </XButton>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppHeader from '../../../components-rf/AppHeader.vue'
import XLabel from '../../../components/common/global/XLabel.vue'
import XButton from '../../../components/common/global/XButton.vue'
import FormInput from '../../../components/utils/FormInput.vue'

export default {
  name: 'TripAddPromoCode',
  components: { AppHeader, XLabel, XButton, FormInput },
  data() {
    return {
      forms: { promo: { value: '' } },
      isLoading: false,
      error: '',
      success: '',
    }
  },
  methods: {
    save() {
      this.isLoading = true
      this.$store
        .dispatch('SearchState/validatePromoCode', {
          promoCode: this.forms.promo.value,
        })
        .then(() => {
          this.success = 'Success!'
          setTimeout(() => this.$router.push('/search/summary'), 500)
        })
        .catch(() => {
          this.error = 'Whoops, this is not a valid promotion.'
          this.isLoading = false
        })
    },
  },
  watch: {
    'forms.promo.value'() {
      this.error = ''
      this.success = ''
    },
  },
  computed: mapState('UserModule', ['user']),
}
</script>

<style scoped lang="scss"></style>
